import { createFeature, createReducer, on } from "@ngrx/store";
import { initialMenuTabState } from "../interfaces/menu-tabs.interface";
import { MenuTabAction } from "./actions";
import { menuTabsReducer } from "./reducers";

const MENU_TABS_FEATURE_NAME = "menuTabs";

export const menuTabsFeature = createFeature({
  name: MENU_TABS_FEATURE_NAME,
  reducer: createReducer(
    initialMenuTabState,
    on(MenuTabAction.setActiveMenu, menuTabsReducer.setActiveMenu)
  ),
});

export const menuTabSlice = {
  name: menuTabsFeature.name,
  reducer: menuTabsFeature.reducer,
} as const;
