import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, from, map, mergeMap, of } from "rxjs";
import { DishMenuActions } from "../actions";
import { AddMenuDishesState } from "../dish.states";
import { DishesApiService } from "../../services/dish-api.service";
import { FileRecord, PreferenceLabelType } from "@menufy/models";

@Injectable()
export class DishMenuEffects {
  addBusinessMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishMenuActions.addMenuDishes),
      mergeMap((action: AddMenuDishesState) => {
        return from(action.dishes).pipe(
          mergeMap((dish) => {
            const formData = new FormData();
            formData.append("name", dish.name || "");
            formData.append("menu", dish?.menu || "");
            formData.append("position", dish?.position ? dish?.position?.toString() : "1");

            if (dish.description) {
              formData.append("description", dish.description || "");
            }

            Array.from((dish.images as File[]) || []).forEach((image) => {
              const file = image as unknown as FileRecord;

              if (file instanceof Blob) {
                formData.append(`images`, image as Blob);
              }
            });

            Array.from(dish.prices || []).forEach((price, i) => {
              formData.append(`prices[${i}][amount]`, price?.amount?.toString() || "");
              if (price?.description) {
                formData.append(`prices[${i}][description]`, price?.description || "");
              }
            });

            Array.from(dish.allergens || []).forEach((allergen, i) => {
              formData.append(`allergens[${i}][name]`, allergen?.name || "");
              formData.append(
                `allergens[${i}][isOptional]`,
                allergen?.isOptional ? "true" : "false"
              );
              formData.append(`allergens[${i}][type]`, PreferenceLabelType.ALLERGEN || "");
            });

            Array.from(dish.diets || []).forEach((allergen, i) => {
              formData.append(`diets[${i}][name]`, allergen?.name || "");
              formData.append(`diets[${i}][isOptional]`, allergen?.isOptional ? "true" : "false");
              formData.append(`diets[${i}][type]`, PreferenceLabelType.DIET || "");
            });

            if (dish?.additionalAllergensInfo) {
              formData.append("additionalAllergensInfo", dish?.additionalAllergensInfo || "");
            }

            if (dish?.ingredients) {
              formData.append("ingredients", dish?.ingredients || "");
            }

            if (dish?.id) {
              return this.dishService.updateMenuDish(formData, dish?.id).pipe(
                map((res) => {
                  return DishMenuActions.addMenuDishesSuccess(res);
                }),
                catchError((error) => of(DishMenuActions.addMenuDishesFailed({ error })))
              );
            } else {
              return this.dishService.addMenuDish(formData).pipe(
                map((res) => {
                  return DishMenuActions.addMenuDishesSuccess(res);
                }),
                catchError((error) => of(DishMenuActions.addMenuDishesFailed({ error })))
              );
            }
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private dishService: DishesApiService) {}
}
