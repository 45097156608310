import { addBusinessMenuFeature } from "./business-menu.slice";

export const {
  selectBusinessMenuState,
  selectSuccess,
  selectPendingRequests,
  selectError,
  selectLoading,
  selectMenus,
} = addBusinessMenuFeature;
