import { Menu, PaginationCursor } from "@menufy/models";

export interface AddBusinessMenuState {
  menus: Array<Partial<Menu>>;
  loading: boolean;
  pendingRequests: number;
  success: boolean;
  error: unknown;
}

export const businessMenuInitialState: AddBusinessMenuState = {
  menus: [],
  loading: false,
  pendingRequests: 0,
  success: false,
  error: null,
};

export interface BusinessMenuState {
  businessMenus: Menu[];
  cursor: PaginationCursor;
  success: boolean;
  error: unknown;
}

export const businessMenuListState: Readonly<BusinessMenuState> = {
  businessMenus: [],
  cursor: {
    next: null,
    previous: null,
  },
  success: false,
  error: null,
};
