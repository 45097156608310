import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { addDishPricesInitialState } from "./dish.states";
import { DishMenuActions } from "./actions";
import addMenuDishReducers from "./reducers/dish.reducers";

const DISH_PRICE_FEATURE_NAME = "dish/price";

export const addDishPriceFeature = createFeature({
  name: DISH_PRICE_FEATURE_NAME,
  reducer: createReducer(
    addDishPricesInitialState,
    on(DishMenuActions.addDishPrices, addMenuDishReducers.addMenuDishPrices),
    on(DishMenuActions.addDishPricesSuccess, addMenuDishReducers.addMenuDishPricesSuccess),
    on(DishMenuActions.addDishPricesFailed, addMenuDishReducers.addMenuDishPricesFailed)
  ),
  extraSelectors: ({
    selectPrices,
    selectDishes,
    selectError,
    selectLoading,
    selectPendingRequests,
    selectSuccess,
  }) => ({
    selectPriceDishes: createSelector(selectDishes, (state) => state),
    selectPriceError: createSelector(selectError, (state) => state),
    selectPriceLoading: createSelector(selectLoading, (state) => state),
    selectPricePendingRequests: createSelector(selectPendingRequests, (state) => state),
    selectPriceSuccess: createSelector(selectSuccess, (state) => state),
    selectAddPricesState: createSelector(
      selectDishes,
      selectError,
      selectLoading,
      selectPendingRequests,
      selectSuccess,
      selectPrices,
      (dishes, error, loading, pendingRequests, success, prices) => ({
        dishes,
        error,
        pendingRequests,
        loading,
        success,
        prices,
      })
    ),
  }),
});

export const addDishPriceSlice = {
  name: addDishPriceFeature.name,
  reducer: addDishPriceFeature.reducer,
} as const;
