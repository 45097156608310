import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, from, map, mergeMap, of } from "rxjs";
import { DishMenuActions } from "../actions";
import { DishesApiService } from "../../services/dish-api.service";
import { Price } from "@menufy/models";
import { AddPrice } from "../dish.states";

@Injectable()
export class DishPricesEffects {
  addDishPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishMenuActions.addDishPrices),
      mergeMap((action) => {
        return from(action.prices as AddPrice[]).pipe(
          mergeMap((price) => {
            const payload: Price = {
              id: price.id || "",
              amount: price.amount || null,
              description: price.description || "",
            };
            if (payload?.id) {
              return this.dishService.updateDishPrice(payload, price.dish).pipe(
                map((res) => {
                  const response = { ...res, dish: price.dish };
                  return DishMenuActions.addDishPricesSuccess(response);
                }),
                catchError((error) => of(DishMenuActions.addDishPricesFailed({ error })))
              );
            } else {
              return this.dishService.addDishPrice(payload, price.dish).pipe(
                map((res) => {
                  const response = { ...res, dish: price.dish };
                  return DishMenuActions.addDishPricesSuccess(response);
                }),
                catchError((error) => of(DishMenuActions.addDishPricesFailed({ error })))
              );
            }
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private dishService: DishesApiService) {}
}
