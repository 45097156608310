import { Menu } from "@menufy/models";
import {
  AddBusinessMenuFailedPropsType,
  AddBusinessMenuSuccessPropsType,
  GetMenusActionProps,
  GetMenusFailedActionProps,
  GetMenusSuccessActionProps,
} from "../actions";
import { AddBusinessMenuState, BusinessMenuState } from "../business-menu.states";

const addBusinessMenus = (state: AddBusinessMenuState, response: AddBusinessMenuState) => ({
  ...state,
  pendingRequests: response?.pendingRequests,
  loading: true,
});

const addBusinessMenuSuccess = (
  state: AddBusinessMenuState,
  response: AddBusinessMenuSuccessPropsType
) => ({
  ...state,
  menus: removeDuplicates([...state.menus, response]) || [],
  pendingRequests: state.pendingRequests - 1,
  loading: state.pendingRequests - 1 > 0,
  success: state.pendingRequests - 1 <= 0,
  error: null,
});

const addBusinessMenuFailed = (
  state: AddBusinessMenuState,
  error: AddBusinessMenuFailedPropsType
) => ({
  ...state,
  menus: removeDuplicates([...state.menus]) || [],
  pendingRequests: state.pendingRequests - 1,
  loading: state.pendingRequests - 1 > 0,
  success: false,
  error: error?.error,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getMenus = (state: BusinessMenuState, action: GetMenusActionProps) => ({
  ...state,
  success: false,
  error: null,
});

const getMenusSuccessReducer = (state: BusinessMenuState, action: GetMenusSuccessActionProps) => ({
  ...state,
  businessMenus:
    (removeDuplicates(
      action.reset ? action?.result?.data : (state.businessMenus || []).concat(action.result.data)
    ) as Menu[]) || [],
  cursor: action.result.cursor,
  success: action.success,
  error: null,
});

const getMenusFailedReducer = (state: BusinessMenuState, action: GetMenusFailedActionProps) => ({
  ...state,
  success: false,
  error: action.error,
});

export function removeDuplicates(menus: Array<Partial<Menu>> | Menu[]) {
  return menus?.filter((menu, index) => menus?.findIndex((m) => m.id === menu.id) === index);
}

export default {
  addBusinessMenus,
  addBusinessMenuSuccess,
  addBusinessMenuFailed,
  getMenus,
  getMenusFailedReducer,
  getMenusSuccessReducer,
};
