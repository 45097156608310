import { Dish } from "@menufy/models";
import { GetDishFailedActionProps, GetDishSuccessActionProps } from "../actions";
import { DishListState } from "../dish-list.states";

const getDishSuccessReducer = (state: DishListState, action: GetDishSuccessActionProps) => ({
  ...state,
  businessDishes: removeDuplicates(
    action.reset ? action?.result?.data : (state.businessDishes || []).concat(action.result.data)
  ),
  success: action.success,
  cursor: action.result.cursor,
  error: null,
});

const getDishFailedReducer = (state: DishListState, action: GetDishFailedActionProps) => ({
  ...state,
  success: false,
  error: action.error,
});

export function removeDuplicates(dishes: Dish[]) {
  return dishes?.filter((dish, index) => dishes?.findIndex((m) => m.id === dish.id) === index);
}

export default {
  getDishSuccessReducer,
  getDishFailedReducer,
} as const;
