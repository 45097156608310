import { Injectable } from "@angular/core";
import { BusinessMenuActions, BusinessMenuState } from "../states";
import { selectBusinessId } from "../../add-business/states";
import { Store } from "@ngrx/store";
import { MENUS_DEFAULT_PAGE_LIMIT } from "../interfaces/list-business-params.interface";
import { selectCursor } from "../states/business-menu-list.selector";

@Injectable({
  providedIn: "root",
})
export class BusinessMenuPaginationService {
  private readonly _cursor = this._store.selectSignal(selectCursor);

  private readonly _businessId = this._store.selectSignal(selectBusinessId);

  constructor(private readonly _store: Store<BusinessMenuState>) {}

  public loadMoreMenus() {
    const next = this._cursor().next;
    const hasNextPage = !!next;
    const business = this._businessId();

    if (!hasNextPage || !business) {
      return;
    }

    this._store.dispatch(
      BusinessMenuActions.getMenus({
        business,
        after: next,
        limit: MENUS_DEFAULT_PAGE_LIMIT,
        success: false,
      })
    );
  }

  public getMenus() {
    const business = this._businessId();

    this._store.dispatch(
      BusinessMenuActions.getMenus({
        business,
        limit: MENUS_DEFAULT_PAGE_LIMIT,
        success: false,
      })
    );
  }
}
