import { createActionGroup, props } from "@ngrx/store";
import { DishListParams } from "../../interfaces";
import { Dish, PaginationResult } from "@menufy/models";

export type GetDishActionProps = DishListParams;

export interface GetDishSuccessActionProps {
  result: PaginationResult<Dish>;
  success: boolean;
  reset?: boolean;
}

export interface GetDishFailedActionProps {
  error: unknown;
}

export const DishListActions = createActionGroup({
  source: "DishList/Apis",
  events: {
    getDishes: props<GetDishActionProps>(),
    getDishesSuccess: props<GetDishSuccessActionProps>(),
    getDishesFailed: props<GetDishFailedActionProps>(),
  },
});
