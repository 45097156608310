import { createFeature, createReducer, on } from "@ngrx/store";
import { businessMenuListState } from "./business-menu.states";
import { BusinessMenuActions } from "./actions";
import businessMenuReducers from "./reducers/business-menu.reducers";

const MENU_FEATURE_NAME = "menu";

export const menuFeature = createFeature({
  name: MENU_FEATURE_NAME,
  reducer: createReducer(
    businessMenuListState,
    on(BusinessMenuActions.getMenus, businessMenuReducers.getMenus),
    on(BusinessMenuActions.getMenusSuccess, businessMenuReducers.getMenusSuccessReducer),
    on(BusinessMenuActions.getMenusFailed, businessMenuReducers.getMenusFailedReducer)
  ),
});

export const menuSlice = {
  name: menuFeature.name,
  reducer: menuFeature.reducer,
} as const;
