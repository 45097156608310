import { addMenuDishFeature } from "./dish.slice";

export const {
  selectDishState,
  selectDishes,
  selectError,
  selectLoading,
  selectPendingRequests,
  selectSuccess,
} = addMenuDishFeature;
