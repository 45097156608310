import { Dish, PaginationCursor } from "@menufy/models";

export interface DishListState {
  businessDishes: Dish[];
  success: boolean;
  cursor: PaginationCursor;
  error: unknown;
}

export const dishListInitialState: Readonly<DishListState> = {
  businessDishes: [],
  success: false,
  cursor: {
    next: null,
    previous: null,
  },
  error: null,
};
