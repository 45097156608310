import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { DishListActions } from "../actions";
import { catchError, map, of, switchMap } from "rxjs";
import { DishesApiService } from "../../services/dish-api.service";

@Injectable()
export class DishListEffects {
  getDishes$ = createEffect(() =>
    this.action$.pipe(
      ofType(DishListActions.getDishes),
      switchMap((listMenuParams) => {
        return this.dishListService.getDishesByBusiness(listMenuParams).pipe(
          map((paginationResult) =>
            DishListActions.getDishesSuccess({
              result: paginationResult,
              reset: true,
              success: true,
            })
          ),
          catchError((error: unknown) => of(DishListActions.getDishesFailed({ error })))
        );
      })
    )
  );

  constructor(private action$: Actions, private dishListService: DishesApiService) {}
}
