import { initialMenuTabState, MenuTabState } from "../../interfaces/menu-tabs.interface";

const setActiveMenu = (state: MenuTabState, response: MenuTabState) => ({
  ...initialMenuTabState,
  activeMenu: response?.activeMenu,
});

export const menuTabsReducer = {
  setActiveMenu,
};
