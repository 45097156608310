import { createActionGroup, props } from "@ngrx/store";
import { Menu, PaginationResult } from "@menufy/models";
import { AddBusinessMenuState } from "../business-menu.states";
import { MenuListParams } from "../../interfaces/list-business-params.interface";

export type AddBusinessMenuPropsType = AddBusinessMenuState;

export type AddBusinessMenuFailedPropsType = { error: unknown };

export type AddBusinessMenuSuccessPropsType = Partial<Menu>;

export type GetMenusActionProps = MenuListParams & { success: boolean };

export interface GetMenusSuccessActionProps {
  result: PaginationResult<Menu>;
  reset?: boolean;
  success: boolean;
}

export interface GetMenusFailedActionProps {
  error: unknown;
}

export const BusinessMenuActions = createActionGroup({
  source: "BusinessMenu/Apis",
  events: {
    addBusinessMenus: props<AddBusinessMenuPropsType>(),
    addBusinessMenuSuccess: props<AddBusinessMenuSuccessPropsType>(),
    addBusinessMenuFailed: props<AddBusinessMenuFailedPropsType>(),
    getMenus: props<GetMenusActionProps>(),
    getMenusSuccess: props<GetMenusSuccessActionProps>(),
    getMenusFailed: props<GetMenusFailedActionProps>(),
  },
});
