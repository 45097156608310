import { createFeature, createReducer, on } from "@ngrx/store";
import { businessMenuInitialState } from "./business-menu.states";
import addBusinessReducers from "./reducers/business-menu.reducers";
import { BusinessMenuActions } from "./actions";

const BUSINESS_MENU_FEATURE_NAME = "businessMenu";

export const addBusinessMenuFeature = createFeature({
  name: BUSINESS_MENU_FEATURE_NAME,
  reducer: createReducer(
    businessMenuInitialState,
    on(BusinessMenuActions.addBusinessMenus, addBusinessReducers.addBusinessMenus),
    on(BusinessMenuActions.addBusinessMenuSuccess, addBusinessReducers.addBusinessMenuSuccess),
    on(BusinessMenuActions.addBusinessMenuFailed, addBusinessReducers.addBusinessMenuFailed)
  ),
});

export const addBusinessMenuSlice = {
  name: addBusinessMenuFeature.name,
  reducer: addBusinessMenuFeature.reducer,
} as const;
