import { createActionGroup, props } from "@ngrx/store";
import { Dish } from "@menufy/models";
import { AddDishPricesState, AddMenuDishesState, AddMenuDishState, AddPrice } from "../dish.states";

export type AddMenuDishesPropsType = AddMenuDishesState;

export type AddMenuDishPropsType = AddMenuDishState;

export type AddMenuDishFailedPropsType = { error: unknown };

export type AddMenuDishSuccessPropsType = Partial<Dish>;

export type GetDishPricesSuccessPropsType = AddPrice;
export type GetDishPricesFailedPropsType = { error: unknown };

export const DishMenuActions = createActionGroup({
  source: "DishMenu/Apis",
  events: {
    addMenuDishesData: props<AddMenuDishesPropsType>(),
    addMenuDishes: props<AddMenuDishesPropsType>(),
    addMenuDish: props<AddMenuDishPropsType>(),
    addMenuDishesSuccess: props<AddMenuDishSuccessPropsType>(),
    addMenuDishesFailed: props<AddMenuDishFailedPropsType>(),
    addMenuDishSuccess: props<AddMenuDishSuccessPropsType>(),
    addMenuDishFailed: props<AddMenuDishFailedPropsType>(),
    addDishPrices: props<AddDishPricesState>(),
    addDishPricesSuccess: props<GetDishPricesSuccessPropsType>(),
    addDishPricesFailed: props<GetDishPricesFailedPropsType>(),
  },
});
