import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, from, map, mergeMap, of, switchMap } from "rxjs";
import { BusinessMenuActions } from "../actions";
import { BusinessMenuService } from "../../services";
import { Menu } from "@menufy/models";

@Injectable()
export class BusinessMenuEffects {
  addBusinessMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessMenuActions.addBusinessMenus),
      mergeMap((action) => {
        return from(action.menus).pipe(
          mergeMap((menu) => {
            if (menu?.id) {
              return this.businessMenuService.updateBusinessMenu(menu, menu.id).pipe(
                map((res) => {
                  return BusinessMenuActions.addBusinessMenuSuccess(res as Partial<Menu>);
                }),
                catchError((error) => of(BusinessMenuActions.addBusinessMenuFailed({ error })))
              );
            } else {
              return this.businessMenuService.addBusinessMenu(menu).pipe(
                map((res) => {
                  return BusinessMenuActions.addBusinessMenuSuccess(res as Partial<Menu>);
                }),
                catchError((error) => of(BusinessMenuActions.addBusinessMenuFailed({ error })))
              );
            }
          })
        );
      })
    )
  );

  getMenus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BusinessMenuActions.getMenus),
      switchMap((listMenusParams) =>
        this.businessMenuService.getMenusByBusiness(listMenusParams).pipe(
          map((paginationResult) =>
            BusinessMenuActions.getMenusSuccess({
              result: paginationResult,
              reset: true,
              success: true,
            })
          ),
          catchError((error: unknown) => of(BusinessMenuActions.getMenusFailed({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private businessMenuService: BusinessMenuService) {}
}
