import { FileRecord } from "@menufy/models";
import {
  AddMenuDishesPropsType,
  AddMenuDishFailedPropsType,
  AddMenuDishSuccessPropsType,
  GetDishPricesFailedPropsType,
  GetDishPricesSuccessPropsType,
} from "../actions";
import { AddDishPricesState, AddMenuDishesState, AddMenuDishState, AddPrice } from "../dish.states";

const addMenuDishesData = (state: AddMenuDishesState, response: AddMenuDishesPropsType) => ({
  ...state,
  dishes: response?.dishes?.length ? response?.dishes : [],
  pendingRequests: response?.dishes?.length || 0,
  loading: true,
});

const addMenuDishes = (state: AddMenuDishesState, response: AddMenuDishesPropsType) => ({
  ...state,
  pendingRequests: response?.dishes?.length || 0,
  loading: true,
});

const addMenuDish = (state: AddMenuDishState) => ({
  ...state,
  success: false,
  error: false,
  loading: true,
});

const addMenuDishesSuccess = (
  state: AddMenuDishesState,
  response: AddMenuDishSuccessPropsType
) => ({
  ...state,
  dishes: removeDuplicates([
    ...state.dishes,
    {
      images: (response.images as FileRecord[]) || [],
      menu: response?.menu || "",
      name: response?.name || "",
      description: response?.description || "",
      prices: response?.prices || [],
      allergens: response?.allergens || [],
      diets: response?.diets || [],
      position: response?.position || 1,
      success: true,
      error: null,
      loading: false,
    },
  ]) as AddMenuDishState[],
  pendingRequests: state.pendingRequests - 1,
  loading: state.pendingRequests - 1 > 0,
  success: state.pendingRequests - 1 <= 0,
  error: null,
});

const addMenuDishesFailed = (state: AddMenuDishesState, error: AddMenuDishFailedPropsType) => ({
  ...state,
  dishes: removeDuplicates([...state.dishes]) as AddMenuDishState[],
  pendingRequests: state.pendingRequests - 1,
  loading: state.pendingRequests - 1 > 0,
  success: false,
  error: error?.error,
});

const addMenuDishSuccess = (state: AddMenuDishState, response: AddMenuDishSuccessPropsType) => ({
  ...state,
  ...response,
  loading: false,
  success: true,
  error: null,
});

const addMenuDishFailed = (state: AddMenuDishState, error: AddMenuDishFailedPropsType) => ({
  ...state,
  loading: false,
  success: false,
  error: error?.error,
});

const addMenuDishPrices = (state: AddDishPricesState, response: AddDishPricesState) => {
  return {
    ...state,
    prices: response.prices,
    pendingRequests: response.prices?.length || 0,
    loading: true,
  };
};

const addMenuDishPricesSuccess = (
  state: AddDishPricesState,
  response: GetDishPricesSuccessPropsType
) => ({
  ...state,
  dishes: removeDuplicates([...state.dishes]) as AddMenuDishState[],
  prices: removeDuplicates([...state.prices, { ...response }]) as AddPrice[],
  pendingRequests: state.pendingRequests - 1,
  loading: state.pendingRequests - 1 > 0,
  success: state.pendingRequests - 1 <= 0,
  error: null,
});

const addMenuDishPricesFailed = (
  state: AddDishPricesState,
  error: GetDishPricesFailedPropsType
) => ({
  ...state,
  dishes: removeDuplicates([...state.dishes]) as AddMenuDishState[],
  prices: removeDuplicates([...state.prices]) as AddPrice[],
  pendingRequests: state.pendingRequests - 1,
  loading: state.pendingRequests - 1 > 0,
  success: false,
  error: error?.error,
});

function removeDuplicates(dishes: AddMenuDishState[] | AddPrice[]) {
  return dishes?.filter((dish, index) => dishes?.findIndex((m) => m.id === dish.id) === index);
}

export default {
  addMenuDishesData,
  addMenuDishes,
  addMenuDish,
  addMenuDishSuccess,
  addMenuDishFailed,
  addMenuDishesSuccess,
  addMenuDishesFailed,
  addMenuDishPrices,
  addMenuDishPricesSuccess,
  addMenuDishPricesFailed,
};
