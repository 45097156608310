import { createFeature, createReducer, on } from "@ngrx/store";
import { addMenuDishState, addMenuDishesState } from "./dish.states";
import { DishMenuActions } from "./actions";
import addMenuDishReducers from "./reducers/dish.reducers";

const DISH_FEATURE_NAME = "dish";

export const addMenuDishFeature = createFeature({
  name: DISH_FEATURE_NAME,
  reducer: createReducer(
    addMenuDishesState,
    on(DishMenuActions.addMenuDishesData, addMenuDishReducers.addMenuDishesData),
    on(DishMenuActions.addMenuDishes, addMenuDishReducers.addMenuDishes),
    on(DishMenuActions.addMenuDishesSuccess, addMenuDishReducers.addMenuDishesSuccess),
    on(DishMenuActions.addMenuDishesFailed, addMenuDishReducers.addMenuDishesFailed)
  ),
});

export const addMenuDishReducer = createReducer(
  addMenuDishState,
  on(DishMenuActions.addMenuDish, addMenuDishReducers.addMenuDish),
  on(DishMenuActions.addMenuDishSuccess, addMenuDishReducers.addMenuDishSuccess),
  on(DishMenuActions.addMenuDishFailed, addMenuDishReducers.addMenuDishFailed)
);

export const addMenuDishSlice = {
  name: addMenuDishFeature.name,
  reducer: addMenuDishFeature.reducer,
} as const;
