import { createFeature, createReducer, on } from "@ngrx/store";
import { dishListInitialState } from "./dish-list.states";
import { DishListActions } from "./actions";
import dishListReducers from "./reducers/dish-list-reducers";

const DISH_LIST_FEATURE_NAME = "dishList";

export const dishListFeature = createFeature({
  name: DISH_LIST_FEATURE_NAME,
  reducer: createReducer(
    dishListInitialState,
    on(DishListActions.getDishesSuccess, dishListReducers.getDishSuccessReducer),
    on(DishListActions.getDishesFailed, dishListReducers.getDishFailedReducer)
  ),
});

export const dishListSlice = {
  name: dishListFeature.name,
  reducer: dishListFeature.reducer,
} as const;
